import _ from 'lodash';
import {
  PATH_COMPONENT__CREDIT_QUARTERLY_TOTAL,
  PATH_COMPONENT__CREDIT_HEALTH_OPTIONS,
  PATH_COMPONENT__CREDIT_HEALTH_EMPLOYER,
  PATH_COMPONENT__CREDIT_HEALTH_PLAN,
  PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
  PATH_COMPONENT__CREDIT_HSA_DETAIL,
  PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL,
  SLUG__CREDIT_QUARTERLY_TOTAL,
  SLUG__CREDIT_HEALTH_OPTIONS,
  SLUG__CREDIT_HEALTH_EMPLOYER,
  SLUG__CREDIT_HEALTH_PLAN,
  SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
  SLUG__CREDIT_HSA_DETAIL,
  SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  SLUG__CREDIT_STUDENT_LOAN_DETAIL,
  SLUG__CREDIT_STUDENT_TUITION_DETAIL,
  SLUG__CREDIT_CHILD_CARE_DETAIL,
  PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL,
  PATH_COMPONENT__CREDIT_EARNED_INCOME_DONE,
  SLUG__CREDIT_EARNED_INCOME_DONE,
  PATH_COMPONENT__CREDIT_COGS_INTRO,
  PATH_COMPONENT__CREDIT_COGS_START_INVENTORY,
  PATH_COMPONENT__CREDIT_COGS_END_INVENTORY,
  SLUG__CREDIT_COGS_INTRO,
  SLUG__CREDIT_COGS_START_INVENTORY,
  SLUG__CREDIT_COGS_END_INVENTORY,
  PATH_COMPONENT__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_CHARITY_AMOUNT,
  PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HOMEOWNER_INTEREST,
  PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED,
  SLUG__CREDIT_HOMEOWNER_ITEMIZED,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
  SLUG__CREDIT_CAPITAL_LOSS_INFO,
  PATH_COMPONENT__CREDIT_CAPITAL_LOSS_INFO,
  PATH_COMPONENT__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_ITEMIZED,
  PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED,
  PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD,
  SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
  SLUG__CREDIT_STANDARD_RESULT,
  PATH_COMPONENT__CREDIT_STANDARD_RESULT,
  PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL,
  SLUG__CREDIT_CHILD_TAX_DETAIL,
  PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING,
  SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING,
  PATH_COMPONENT__CREDIT_ENERGY_INFO,
  SLUG__CREDIT_ENERGY_INFO,
  SLUG__CREDIT_TEACHING_INFO,
  PATH_COMPONENT__CREDIT_TEACHING_INFO,
  PATH_COMPONENT__CREDIT_DISALLOWED,
  SLUG__CREDIT_DISALLOWED,
  PATH_COMPONENT__CREDIT_DISALLOWED_NAMES,
  SLUG__CREDIT_DISALLOWED_NAMES,
  PATH_COMPONENT__CREDIT_QBI,
  SLUG__CREDIT_QBI,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
  SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
  SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
  SLUG__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTRO,
  SLUG__CREDIT_BUSINESS_LOANS_INTRO,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
  SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
  SLUG__CREDIT_BUSINESS_LOANS_INTEREST,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT,
  SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT,
  SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM,
  SLUG__CREDIT_BUSINESS_LOANS_CONFIRM,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_HAS_1095A,
  PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY,
  SLUG__CREDIT_HSA_DETAIL_FAMILY,
  PATH_COMPONENT__CREDIT_SAVERS_INTRO,
  SLUG__CREDIT_SAVERS_INTRO,
  PATH_COMPONENT__CREDIT_SAVERS_INFO,
  SLUG__CREDIT_SAVERS_INFO,
  PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL,
  SLUG__CREDIT_SAVERS_WITHDRAWAL,
  SLUG__CREDIT_COGS_JOBS,
  PATH_COMPONENT__CREDIT_COGS_TOTAL_EXPENSES,
  SLUG__CREDIT_COGS_TOTAL_EXPENSES,
  PATH_COMPONENT__CREDIT_COGS_JOBS,
  PATH_COMPONENT__CREDIT_HEALTH_ELIGIBLE,
  SLUG__CREDIT_HEALTH_ELIGIBLE,
  PATH_COMPONENT__CREDIT_HEALTH_RECEIVED,
  PATH_COMPONENT__CREDIT_HEALTH_SELF,
  SLUG__CREDIT_HEALTH_RECEIVED,
  SLUG__CREDIT_HEALTH_SELF,
  SLUG__CREDIT_HEALTH_SELF_TOTAL,
  PATH_COMPONENT__CREDIT_HEALTH_SELF_TOTAL,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS,
  PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL_NO_DEPENDENTS,
  SLUG__CREDIT_CHILD_TAX_DETAIL_NO_DEPENDENTS,
  COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { INCOME_COLLECTION_TYPES, INCOME_ENDPOINT_ATTRIBUTES } from '@app/src/taxflow/sections/income/incomeConstants';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { getFilingStatus } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__CREDIT_STANDARD_RESULT]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_QUARTERLY_TOTAL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HEALTH_EMPLOYER]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_PLAN,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HSA_DETAIL_FAMILY]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CAPITAL_LOSS_INFO]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_LOAN_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_TUITION_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_QUALIFY,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHILD_CARE_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHARITY_AMOUNT]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_JOBS]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_COGS_START_INVENTORY,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_START_INVENTORY]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_COGS_TOTAL_EXPENSES,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_TOTAL_EXPENSES]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_COGS_END_INVENTORY,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_END_INVENTORY]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HOMEOWNER_ITEMIZED]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHILD_TAX_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHILD_TAX_DETAIL_NO_DEPENDENTS]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_ENERGY_INFO]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_TEACHING_INFO]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_DISALLOWED_NAMES]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_INTRO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_AMOUNT]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_PERCENT]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_CONFIRM]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_SAVERS_INTRO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_SAVERS_INFO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_SAVERS_WITHDRAWAL]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HEALTH_OPTIONS]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_ELIGIBLE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HEALTH_SELF_TOTAL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_PLAN,
      questionnaireEarlyExit: false
    }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [SLUG__CREDIT_STANDARD_DEDUCTION]: {
      itemized: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED,
        questionnaireEarlyExit: false
      },
      standard: {
        nextPathComponent: PATH_COMPONENT__TAX_HOME,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_STUDENT_TUITION_QUALIFY]: {
      0: {
        nextPathComponent: PATH_COMPONENT__TAX_HOME,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_DISALLOWED]: {
      0: {
        nextPathComponent: PATH_COMPONENT__TAX_HOME,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_DISALLOWED_NAMES,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST,
        questionnaireEarlyExit: false
      },
      2: {
        nextPathComponent: PATH_COMPONENT__TAX_HOME,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_SAVERS_INFO]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__TAX_HOME,
        questionnaireEarlyExit: false
      }
    }
  };
};

export const getNextPathComponent = ({ question, answer, deductions, queryResults }) => {
  if (question.slug === SLUG__CREDIT_HEALTH_ELIGIBLE) {
    const eligibleAnswer = _.get(answer, ['value'], '0');
    const healthOptionsAnswer = _.chain(queryResults)
      .find((item) => item.slug === ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS)
      .get('answer.value', '0')
      .value();

    if (eligibleAnswer === '0' && healthOptionsAnswer === '0') {
      return { nextPathComponent: PATH_COMPONENT__TAX_HOME };
    }

    return {
      nextPathComponent:
        eligibleAnswer === '0' ? PATH_COMPONENT__CREDIT_HEALTH_SELF : PATH_COMPONENT__CREDIT_HEALTH_RECEIVED
    };
  } else if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
    if (_.get(deductions, 'itemized')) {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS };
    } else {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_RESULT };
    }
  } else if (question.slug === SLUG__CREDIT_HOMEOWNER_DETAIL) {
    const interest = _.get(answer, ['value', SLUG__CREDIT_HOMEOWNER_INTEREST, 'value']);
    const standardDeduction = _.get(deductions, 'standard');

    if (interest > standardDeduction) {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED };
    } else {
      return { nextPathComponent: PATH_COMPONENT__TAX_HOME };
    }
  } else if (question.slug === SLUG__CREDIT_HEALTH_SELF) {
    if (_.get(answer, ['value']) === '1') {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_SELF_TOTAL };
    } else {
      const creditHealthReceived = _.chain(queryResults)
        .find((item) => item.slug === 'credit-health-received')
        .get('answer.value', '0')
        .value();

      const creditHealthEligible = _.chain(queryResults)
        .find((item) => item.slug === 'credit-health-eligible')
        .get('answer.value', '0')
        .value();

      if (creditHealthReceived === '1' && creditHealthEligible === '2') {
        return { nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_PLAN };
      }

      return { nextPathComponent: PATH_COMPONENT__TAX_HOME };
    }
  } else if (question.slug === SLUG__CREDIT_HEALTH_RECEIVED) {
    const creditHealthEligible = _.chain(queryResults)
      .find((item) => item.slug === 'credit-health-eligible')
      .get('answer.value', '0')
      .value();
    if (creditHealthEligible === '2') {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_SELF };
    }
    return { nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_PLAN };
  } else if (question.slug === SLUG__CREDIT_HEALTH_PLAN) {
    const isUserClaimingTheyHaveA1095A =
      _.get(answer, ['value', ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_HAS_1095A, 'value']) === '1';
    const doesUserHaveA1095A = _.some(queryResults, { coll_type: COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A });

    return isUserClaimingTheyHaveA1095A && !doesUserHaveA1095A
      ? {
          nextPathComponent: PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
          questionnaireEarlyExit: false,
          questionnaireNextPathComponent: PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL
        }
      : {
          nextPathComponent: PATH_COMPONENT__TAX_HOME,
          questionnaireEarlyExit: false
        };
  } else if (question.slug === SLUG__CREDIT_HSA_DETAIL) {
    const filingStatus = getFilingStatus({ queryResults });
    return ['married', 'married_separate', 'head'].includes(filingStatus)
      ? { nextPathComponent: PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY }
      : { nextPathComponent: PATH_COMPONENT__TAX_HOME };
  } else if (question.slug === SLUG__CREDIT_COGS_INTRO) {
    const allJobs = _.chain(queryResults)
      .filter(
        (item) =>
          item.coll_type === INCOME_COLLECTION_TYPES.FREELANCE &&
          Number(item.coll_id) > 0 &&
          [INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_JOB_NAME, INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_WHO].includes(item.slug)
      )
      .reduce((acc, item) => {
        if (!acc[item.coll_id]) {
          acc[item.coll_id] = {};
        }
        const slugMap = {
          [INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_JOB_NAME]: 'jobName',
          [INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_WHO]: 'who'
        };
        acc[item.coll_id][slugMap[item.slug]] = item.answer.value;
        return acc;
      }, {})
      .values()
      .value();
    const jobs = _.uniqWith(allJobs, (a, b) => a.jobName === b.jobName && a.who === b.who);

    if (answer.value === '0') {
      return {
        nextPathComponent: PATH_COMPONENT__TAX_HOME,
        questionnaireEarlyExit: false
      };
    } else if (jobs.length > 1) {
      return {
        nextPathComponent: PATH_COMPONENT__CREDIT_COGS_JOBS,
        questionnaireEarlyExit: false
      };
    } else {
      return {
        nextPathComponent: PATH_COMPONENT__CREDIT_COGS_START_INVENTORY,
        questionnaireEarlyExit: false
      };
    }
  } else {
    return { nextPathComponent: null };
  }
};

export const getNextQuery = ({ question, nextPathComponent }) => {
  if (
    question.slug === SLUG__CREDIT_HEALTH_PLAN &&
    nextPathComponent === PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL
  ) {
    return {
      collectionId: 1
    };
  }
  return {};
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__CREDIT_STANDARD_DEDUCTION]: SLUG__CREDIT_STANDARD_DEDUCTION,
    [PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE]: SLUG__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
    [PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED]: SLUG__CREDIT_STANDARD_ITEMIZED,
    [PATH_COMPONENT__CREDIT_STANDARD_RESULT]: SLUG__CREDIT_STANDARD_RESULT,
    [PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS]: SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
    [PATH_COMPONENT__CREDIT_EARNED_INCOME_DONE]: SLUG__CREDIT_EARNED_INCOME_DONE,
    [PATH_COMPONENT__CREDIT_QUARTERLY_TOTAL]: SLUG__CREDIT_QUARTERLY_TOTAL,
    [PATH_COMPONENT__CREDIT_HEALTH_OPTIONS]: SLUG__CREDIT_HEALTH_OPTIONS,
    [PATH_COMPONENT__CREDIT_HEALTH_EMPLOYER]: SLUG__CREDIT_HEALTH_EMPLOYER,
    [PATH_COMPONENT__CREDIT_HEALTH_PLAN]: SLUG__CREDIT_HEALTH_PLAN,
    [PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL]: SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
    [PATH_COMPONENT__CREDIT_HSA_DETAIL]: SLUG__CREDIT_HSA_DETAIL,
    [PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY]: SLUG__CREDIT_HSA_DETAIL_FAMILY,
    [PATH_COMPONENT__CREDIT_CAPITAL_LOSS_INFO]: SLUG__CREDIT_CAPITAL_LOSS_INFO,
    [PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL]: SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
    [PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL]: SLUG__CREDIT_STUDENT_LOAN_DETAIL,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_QUALIFY]: SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY]: SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING]: SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL]: SLUG__CREDIT_STUDENT_TUITION_DETAIL,
    [PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL]: SLUG__CREDIT_CHILD_CARE_DETAIL,
    [PATH_COMPONENT__CREDIT_CHARITY_AMOUNT]: SLUG__CREDIT_CHARITY_AMOUNT,
    [PATH_COMPONENT__CREDIT_COGS_INTRO]: SLUG__CREDIT_COGS_INTRO,
    [PATH_COMPONENT__CREDIT_COGS_JOBS]: SLUG__CREDIT_COGS_JOBS,
    [PATH_COMPONENT__CREDIT_COGS_START_INVENTORY]: SLUG__CREDIT_COGS_START_INVENTORY,
    [PATH_COMPONENT__CREDIT_COGS_TOTAL_EXPENSES]: SLUG__CREDIT_COGS_TOTAL_EXPENSES,
    [PATH_COMPONENT__CREDIT_COGS_END_INVENTORY]: SLUG__CREDIT_COGS_END_INVENTORY,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL]: SLUG__CREDIT_HOMEOWNER_DETAIL,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD]: SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING]: SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED]: SLUG__CREDIT_HOMEOWNER_ITEMIZED,
    [PATH_COMPONENT__CREDIT_HEALTH_ELIGIBLE]: SLUG__CREDIT_HEALTH_ELIGIBLE,
    [PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL]: SLUG__CREDIT_CHILD_TAX_DETAIL,
    [PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL_NO_DEPENDENTS]: SLUG__CREDIT_CHILD_TAX_DETAIL_NO_DEPENDENTS,
    [PATH_COMPONENT__CREDIT_ENERGY_INFO]: SLUG__CREDIT_ENERGY_INFO,
    [PATH_COMPONENT__CREDIT_TEACHING_INFO]: SLUG__CREDIT_TEACHING_INFO,
    [PATH_COMPONENT__CREDIT_DISALLOWED]: SLUG__CREDIT_DISALLOWED,
    [PATH_COMPONENT__CREDIT_DISALLOWED_NAMES]: SLUG__CREDIT_DISALLOWED_NAMES,
    [PATH_COMPONENT__CREDIT_QBI]: SLUG__CREDIT_QBI,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTRO]: SLUG__CREDIT_BUSINESS_LOANS_INTRO,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE]: SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST]: SLUG__CREDIT_BUSINESS_LOANS_INTEREST,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT]: SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT]: SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM]: SLUG__CREDIT_BUSINESS_LOANS_CONFIRM,
    [PATH_COMPONENT__CREDIT_SAVERS_INTRO]: SLUG__CREDIT_SAVERS_INTRO,
    [PATH_COMPONENT__CREDIT_SAVERS_INFO]: SLUG__CREDIT_SAVERS_INFO,
    [PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL]: SLUG__CREDIT_SAVERS_WITHDRAWAL,
    [PATH_COMPONENT__CREDIT_HEALTH_RECEIVED]: SLUG__CREDIT_HEALTH_RECEIVED,
    [PATH_COMPONENT__CREDIT_HEALTH_SELF]: SLUG__CREDIT_HEALTH_SELF,
    [PATH_COMPONENT__CREDIT_HEALTH_SELF_TOTAL]: SLUG__CREDIT_HEALTH_SELF_TOTAL
  };
};
